<template>
  <div>
    <div class="ml-4 dropdown">
      <button data-toggle="dropdown" class="btn btn-sm btn-secondary">
        <i :class="icon" />
      </button>
      
      <div class="dropdown-menu dropdown-menu-right">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      icon: { type: String, default: null }
    }
  }
</script>