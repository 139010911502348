<template>
  <div>
    <div class="row align-items-center">
      <div class="col-2">
        <select
          :value="item[itemSortingKey]"
          class="form-control"
          @change="swap"
        >
          <option
            v-for="position in positions"
            :key="position"
          >
            {{ position }}
          </option>
        </select>
      </div>

      <div class="col-4">
        <slot />
      </div>

      <button
        class="text-secondary"
        @click="remove"
      >remover</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      itemSortingKey: { type: String, default: null },
      item: { type: Object, default: () => ({}) },
      maxPositions: { type: Number, default: 1 }
    },
    
    computed: {
      positions() {
        return [...Array(this.maxPositions).keys()].map(p => p + 1)
      }
    },

    methods: {
      remove() {
        this.$emit('remove', this.item)
      },

      swap($event) {
        this.$emit('swap', {
          item: this.item,
          newPosition: +$event.target.value
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .faded {
    color: darken(#fff, 55%);
  }
</style>