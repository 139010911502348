<template>
  <button
    class="dropdown-item text-secondary"
    v-on="listeners"
  >
    <slot />
  </button>
</template>

<script>
  export default {
    computed: {
      listeners () {
        const { input, ...listeners } = this.$listeners
        return listeners
      }
    }
  }
</script>