<template>
  <div>
    <SortableItem
      v-for="item in items"
      :key="item[itemKey]"
      :item="item"
      :item-sorting-key="itemSortingKey"
      :max-positions="maxPositions"
      @remove="removeItem"
      @swap="swapItem"
      class="mb-2"
    >
      <slot v-bind:item="item" />
    </SortableItem>
  </div>
</template>

<script>
  import SortableItem from '@/components/utils/sorting/SortableItem'

  export default {
    components: {
      SortableItem,
    },

    props: {
      items: { type: Array, default: () => ([]) },
      maxPositions: { type: Number, default: 1 },
      itemKey: { type: String, default: null },
      itemSortingKey: { type: String, default: null }
    },

    methods: {
      removeItem(item) {
        const index = this.items
          .findIndex(p => p[this.itemKey] == item[this.itemKey])

        const itemRemoved = this.items.splice(index, 1)

        this.reorderitems()
        
        this.$emit('item-removed', itemRemoved[0])
      },

      reorderitems () {
        const items = this.items.sort((p1, p2) => p1[this.itemSortingKey] - p2[this.itemSortingKey])
        let index = 1
        items.forEach(item => item[this.itemSortingKey] = index++)
      },

      swapItem ({ item, newPosition }) {
        const operations = {
          'true': {
            filter: item => item[this.itemSortingKey] <= newPosition,
            each: item => item[this.itemSortingKey]--
          },

          'false': {
            filter: item => item[this.itemSortingKey] >= newPosition,
            each: item => item[this.itemSortingKey]++
          }
        }

        const operationCondition = (newPosition > item[this.itemSortingKey]).toString()
        item[this.itemSortingKey] = newPosition
        this.items
          .filter(p => p[this.itemKey] != item[this.itemKey])
          .filter(operations[operationCondition].filter)
          .forEach(operations[operationCondition].each)

        let index = 1
        this.items
          .sort((p1, p2) => p1[this.itemSortingKey] - p2[this.itemSortingKey])
          .forEach(item => item[this.itemSortingKey] = index++)
      },
    }
  }
</script>